.printdiv1>p:nth-child(1),
.printdiv2>p:nth-child(1),
.printdiv5>p:nth-child(1) {
    min-height: 48px !important;
}

@media (min-width : 1025px) {
    .printdiv1 {
        min-width: 20% !important;
    }
}

table.dsTable {
    display: table !important;
}

table.dsTable td {
    text-align: left !important;
}

.printAlone {
    display: none;
}
.magixAccSummary {
    padding-right: 2rem !important;
}
.nav-tabs .nav-link {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

@media (max-width : 1440px) {
    .dynamicCol1 {
        border: 0 !important;
        min-width: 100% !important;
        min-height: 100px;
    }

    .dynamicCol2 {
        min-width: 100% !important;
        padding-left: 1rem !important;
    }
}

@media print {
    .dynamicCol1 {
        min-width: 50% !important;
        border-right: 1px solid #dee2e6 !important;
    }

    .dynamicCol2 {
        min-width: 50% !important;
    }
    .printAlone {
        display: flex;
    }

    .accountPrintOut {
        display: none;
    }

    .printdiv1 {
        max-width: 20% !important;
    }

    .printdiv2,
    .printdiv5 {
        max-width: 16.6% !important;
    }

    .maindiv3 {
        max-width: 50% !important;
    }
    .maindiv4 {
        max-width: 25% !important;
    }
    .accSummaryPrint .card-body{
        padding-top: .5rem !important;
        padding-bottom: .5rem !important;
    }
}