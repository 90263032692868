#associateLoginBtn{
    Width: 300px;
}
#associateLoginBtn > button{
    background-color: #5C068C !important;
}

#Illustration{
    width: 100%;
    height: 100vh;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: left;
    object-position: left;
}

#Illustration1{
        width: 100%;
        max-height: 100vh;
        /* -o-object-fit: cover;
        object-fit: cover; */
}

#Illustration2{
    width: 100%;
    max-height: 50vh;
    /* -o-object-fit: cover;
    object-fit: cover; */
}

#Illustration3{
    width: 100%;
    max-height: 75vh;
    /* -o-object-fit: cover;
    object-fit: cover; */
}

#dailyStyle3{
    display: flex;
    width: 100%;
    height: 25vh;
}  

#dailyStyle2{
    display: flex;
    width: 100%;
    height: 50vh;
}   

#dailyStyle1{
    display: flex;
    width: 100%;
    height: 100vh;
} 

#dailyNote {
    background-color: #f8f6fa  !important;
    height: 700px !important;
}

@media (max-width: 280px) {
    #associateLoginBtn{
      max-width: 200px;
    }
}
.alertMsg{
    word-break: break-all;
}