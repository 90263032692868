#addbtn {
  display: flex !important;
  justify-content: right;
}
#heading {
  display: flex !important;
  justify-content: left;
}
/* .font-weight-bold {
  color: black !important;
} */
.pagination {
  margin-top: 1rem;
}
.filter-label {
  border: 1px solid #1B1B1B !important;
}
.userListTable > .react-bootstrap-table {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-top: 0;
}
.userListTable > .react-bootstrap-table th {
  border-top: 0;
  background-color: #5C068C;
  color: white;
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 17px !important;
}
.emptyTable > .react-bootstrap-table {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-top: 0;
}
.emptyTable > .react-bootstrap-table th {
  border-top: 0;
  background-color: #5C068C;
  color: white;
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 17px !important;
}
.emptyTable > .react-bootstrap-table > .table  {
  margin-bottom: 3rem;
}
.emptyTable > div > .react-bootstrap-table-pagination-total {
  display: none;
}
.emptyTable > div > .pagination {
  display: none;
}
.input-group-text {
  white-space: nowrap; 
  width: 185px; 
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.chip{
  background-color: #e9ecef !important;
  border-radius: 5% !important;
  color: #1B1B1B !important;
  padding: 0px 5px !important;
}
.chip > .icon_cancel {
  background-color: #5C068C;
  border-radius: 50%;
  margin-top: 0 !important;
}
.multiSelectContainer li {
  padding: 0 !important;
  background-color: #fff !important;
  color: #1B1B1B !important;
}
.multiSelectContainer li:hover, .highlightOption {
  background-color: #5C068C !important;
  color: #fff !important;
}

/* .userListTable >  .react-bootstrap-table th[data-row-selection] {
  width: 15px;
} */
@media (min-width: 768px) and (max-width: 1024px){
  .mr-md-4,
  .mx-md-4 {
    margin-right: 0rem !important;
  }
  #matchnotfound{
    position: relative;
    top: 99px !important;
    z-index: 1990 !important;
  }
}
@media (max-width: 767px) {
  #matchnotfound{
    position: relative;
    top: 99px !important;
    z-index: 1990 !important;
  }
}
#cardrow > .pagination {
  float: right;
  margin-bottom: 0;
  margin-top: 8px;
}
#cardrow > .pagination {
  float: right;
  margin-bottom: 0;
  margin-top: 8px;
}

@media (min-width: 1025px)
{
  #cardrow{
    display: none;
  } 
  
  #cardnotfound{
    display: none;
  }
  .searchColumn1{
    border-radius: 15px 0px 0px 0px !important;
  }
  .searchColumn3{
    border-radius: 0px 15px 0px 0px !important;
  }
}
.react-bootstrap-table>table>thead>tr>th .filter-label {
  display: block!important;
  border: 1px solid;
  border-radius: 5px;
}
.closeIcon{
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: -34px;
}
.drp{
-webkit-appearance: listbox !important;
}
.react-bootstrap-table th[data-row-selection] {
  width: 15px;
}
.userId{
  width: 96px;
}
.roleName {
  width: 15%;
}
.homeStore {
  width: 12%;
}
.thead{
background-color: #5C068C !important;
}
.filterText{
border-color: black;
}
/* thead {
  border-bottom: 1px solid black;
  background-color: #5C068C !important;
  color: white !important;
} */
.command_bar{
  background-color: white;
  border: transparent;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #5C268C !important;

}
.add_button {
  border: none;
  background-color: transparent;
  color: #5C068C;
  /* width: 70px; */
  border-radius: 5px;
  padding: 9px;
 
}
.edit_button {
  border: none;
  background-color: transparent;
  color: #5C068C;
  width: 90px;
  border-radius: 5px;
  margin-left: 25px;
  padding: 9px;
}
.edit_button_unselect {
  border: none;
  background-color: transparent;
  opacity: 0.5;
  color: #5C068C;
  width: 90px;
  border-radius: 5px;
  margin-left: 25px;
  padding: 9px;
}
.status{
  width: 100px;
}
#matchnotfound{
  position: relative;
  top: -40px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2eef8 !important;
}
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%);
}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


