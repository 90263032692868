#root {
  height: 100%; 
}
.app {
  height: 100%;
  display: flex;
  position: relative;
  background-color: #f8f6fa;
}
#main{
  background-color: #f8f6fa !important;
  }
.activePage > .pro-inner-item{
  background-color: #f8f6fa;
  color: #5C068C !important;
}

.text-primary{
  color: #f8f6fa !important;
}
.text-secondary{
  color: #5C068C !important;
}
.bg-primary{
  background-color: #f8f6fa !important;
}
.bg-secondary{
  background-color: #5C068C !important;
}
.customBorderRadius{
  border-radius: 15px;
}
input{
  border-color: white !important;
}
.storeNo{
  background-color: #5C068C !important;
  color: white !important;
}
.pro-sidebar{
  background-color: #5C068C !important;
  color: white !important;
}
.pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item {
  color: #5C068C !important;
  background-color: #f8f6fa !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #f8f6fa;
}
.pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item:hover , .pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item > .pro-item-content:hover {
  color: #5C068C !important;
}
.pro-sidebar .pro-menu {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pro-sidebar > .pro-sidebar-inner {
  background: transparent;  
}

span {
  margin-left: 5px;
  margin-right: 5px; 
}
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
a {
  text-decoration: none;
  color: #888;
}
.font-weight-bold{
  font-weight: 1000 !important;
}
.selectStyle{
  padding: 4px;
  color: #5C068C !important;
  background: white !important;
}
.printSummaryPanelHide{
  display: none;
}
.printSummaryPanelShow{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1010;
}
.printSummaryPanel {
  width: 50%;
  float: right;
  background-color: white;
  height: 100%;
  overflow-y: auto;
}
button.bg-primary:hover {
  background-color: #f8f6fa !important;
  color: #5C068C !important;
}

div.ShowContent {
  display: block;
  }
  div.HideContent {
  display: none;
  }
#customerInfo {
  display: none;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}
.printSummaryFields{
  border-color: #5C068C !important;
}
.printDatas > .row > .col-6 > .row > .col-6 > p.h5 {
  word-break: break-all !important;
}
.offersDesign {
  margin-left: 0;
  margin-right: 0;;
  margin-bottom: .5rem;
  display: inline-block;
}
a.text-primary:focus, a.text-primary:hover {
  color: #fff!important;
}
a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #5C068C!important;
}
@media (max-width: 767px) {
  .pro-sidebar > .pro-sidebar-inner {
    background: #5C068C;  
    color: #f8f6fa;
  }
  .printSummaryPanel {
    width: 85%;
  }
  .reCancel, .reStay, .reLogout {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px)
{
  .printSummaryPanel {
    width: 75%;
  }
  .printSummaryLabel{
    display: block;
    text-align: right;
  }
  .printToStoreLabel{
    display: block;
    text-align: right;
  }
}

@media (min-width: 1025px)
{
  .sidePanelClose{
    display: none;
  }
  .printSummaryLabel{
    display: block;
    text-align: right;
  }
  .printToStoreLabel{
    display: block;
    text-align: right;
  }
}

@media print {
  #customerInfo {
    
    display: initial;
    background-color: #f8f6fa !important;
  }
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  padding: 5px;
  border-color: #ffffff;
}

@media (min-width: 576px){
  .magixDialog > .modal-dialog {
    max-width: 1000px;
  }
}

@media(max-width : 575px) {
  .magixDialog {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width : 1024px) {
  .magixDialog {
    display: none !important;
  }
}
