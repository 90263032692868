#isAutoPayEnabled, #isSynchronySendEmail, #isAddressAutoComplete, 
#isCoBorrowerEnable, #isMasterAddressUpdationEnable, #isWaterFallEnable, #isCssAcctCreation, #isInsuranceEnable, #isEstatementEnable,
#isFortivaInsuranceCheckBox, #isDanielsInsuranceCheckBox,#danielsFileAClaim, #danielsCancelEnrollment,
#fortivaFileaClaim,#fortivaCancelEnrollment, #isDanielsApplicationFlowChanges {
    transform: scale(1.5);
}

#isAutoPayEnabled.form-control:focus, #isSynchronySendEmail.form-control:focus, 
#isAddressAutoComplete.form-control:focus, #isCoBorrowerEnable.form-control:focus,
#isWaterFallEnable.form-control:focus,#isCssAcctCreation.form-control:focus,
#isMasterAddressUpdationEnable.form-control:focus, #isInsuranceEnable.form-control:focus,
#isEstatementEnable.form-control:focus, #isFortivaInsuranceCheckBox.form-control:focus,
 #isDanielsInsuranceCheckBox.form-control:focus, #danielsFileAClaim.form-control:focus,
 #danielsCancelEnrollment.form-control:focus,#fortivaFileaClaim.form-control:focus,
 #fortivaCancelEnrollment.form-control:focus, #isDanielsApplicationFlowChanges.form-control:focus {
    box-shadow: none !important;
}