.transactionHistoryTable > .react-bootstrap-table th {
    border-top: 0;
    background-color: #5C068C;
    color: white;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 17px !important;
}
.react-bootstrap-table .reset-expansion-style {
    background-color: white !important;
}
.react-bootstrap-table .reset-expansion-style #expandRow .react-bootstrap-table .table thead th{
    border-top: 0 !important;
    padding-top: 1rem;
    border-radius: 0 !important;
    background-color: white;
    color: #1B1B1B;
}
.react-bootstrap-table .reset-expansion-style #expandRow .react-bootstrap-table .table tr:nth-child(even) {
    background-color: white !important;
    
}
.react-bootstrap-table .reset-expansion-style #expandRow .react-bootstrap-table .table tbody tr:hover {
    background-color: white;
}
.transactionHistoryTable > .react-bootstrap-table th.expand-cell-header {
    width: 80px !important;
    text-align: center;
}
.header1, .header2, .header3 {
    width: 11% !important;
}
.infoBox p{
    margin: auto;
}
#cardnotfound {
    display: none;
}
.searchButton {
    width: 50%;
}

@media (max-width: 767px) {
    .infoBox{
        flex-direction: column;
    }
    .infoBox p{
        margin: initial;
        border: 0 !important;
    }
    #cardnotfound {
        display: block;
    }
    .searchButton {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .infoBox{
        flex-direction: column;
    }
    .infoBox p{
        margin: initial;
        border: 0 !important;
    }
    #cardnotfound {
        display: block;
    }
    .searchButton {
        width: 75%;
    }
}

@media (min-width : 1025px) {
    #transactionHistorySearchDiv {
        min-width: 20% !important;
    }
}