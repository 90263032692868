.cancel-button {
    box-sizing: border-box;
    border: 1px solid #5c068c;
    color: #5c068c;
    padding: 8px;
}

.cancel-button:hover {
    background-color: #5c068c;
    color: white;
}

.confirm-button {
    background-color: #5c068c;
    color: white;
    padding: 8px;
}

.confirm-button:hover {
    background-color: #5c068c;
    color: white;
}